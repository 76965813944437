import React, { useState } from "react"
import { Link } from "gatsby"
import { Helmet } from "react-helmet"

// Components
import Seo from "../../../components/seo"
import SidebarLayout from "../../../layouts/SidebarLayout"
import Section from "../../../components/section/Section"
import ContentNavigation from "../../../components/scrollspy"

// SEO Image
import ogImage from "../../../images/seo/modals/modals.png"

// Libraries / Components for Preview
import PreviewBlock from "../../../components/preview"

import ModalHeroComponent from "../../../library/components/modal/react/_preview/ModalHeroComponent"

// Preview
import ModalBasicPreview from "../../../library/components/modal/react/_preview/basic"
import ModalActionButtonPreview from "../../../library/components/modal/react/_preview/action_button"
import ModalActionButtonsPreview from "../../../library/components/modal/react/_preview/action_buttons"
import ModalIconActionButtonsPreview from "../../../library/components/modal/react/_preview/icon_action_buttons"
import ModalFormPreview from "../../../library/components/modal/react/_preview/form"

// All
const ModalBasicJsx = require("!!raw-loader!../../../library/components/modal/react/basic.jsx")
const ModalBasicHTML = require("!!raw-loader!../../../library/components/modal/html/basic.html")

const ModalActionButtonJsx = require("!!raw-loader!../../../library/components/modal/react/action_button.jsx")
const ModalActionButtonHTML = require("!!raw-loader!../../../library/components/modal/html/action_button.html")

const ModalActionButtonsJsx = require("!!raw-loader!../../../library/components/modal/react/action_buttons.jsx")
const ModalActionButtonsHTML = require("!!raw-loader!../../../library/components/modal/html/action_buttons.html")

const ModalIconActionButtonsJsx = require("!!raw-loader!../../../library/components/modal/react/icon_action_buttons.jsx")
const ModalIconActionButtonsHTML = require("!!raw-loader!../../../library/components/modal/html/icon_action_buttons.html")

const ModalFormJsx = require("!!raw-loader!../../../library/components/modal/react/form.jsx")
const ModalFormHTML = require("!!raw-loader!../../../library/components/modal/html/form.html")

export default function ModalsPage() {
  const [activeTabs, setActiveTabs] = useState([
    {
      component_name: "ModalBasic",
      title: "Basic modal",
      active_tab: 1,
    },
    {
      component_name: "ModalActionButton",
      title: "With single action button",
      active_tab: 1,
    },
    {
      component_name: "ModalActionButtons",
      title: "With multiple action buttons",
      active_tab: 1,
    },
    {
      component_name: "ModalIconActionButtons",
      title: "With icon & action buttons",
      active_tab: 1,
    },
    {
      component_name: "ModalForm",
      title: "With form",
      active_tab: 1,
    },
  ])

  const getActiveTabs = (id, tab) => {
    let updatedActiveTabs = activeTabs.map(activeTab => {
      if (activeTab.component_name === id) {
        return { ...activeTab, active_tab: tab }
      }
      return activeTab
    })
    setActiveTabs(updatedActiveTabs)
  }

  return (
    <SidebarLayout>
      <Seo
        title="Tailwind CSS Modals - WindUI Component Library"
        ogtitle="Tailwind CSS Modals - WindUI Component Library"
        description="Modal components, inform users about a task & can contain critical information, require decisions, or involve multiple tasks. Built with Tailwind CSS by WindUI."
        ogdescription="Modal components, inform users about a task & can contain critical information, require decisions, or involve multiple tasks. Built with Tailwind CSS by WindUI."
        url="components/modals/"
        ogimage={ogImage}
        author="Wind UI"
        keywords="Tailwind CSS, Tailwind CSS Modals, Modals, Modal Components, WindUI, windui, wind ui"
        robots="index, follow"
        revisit_after="7 days"
      />
      <Helmet
        style={[
          {
            cssText: `
              input:-webkit-autofill,
              input:-webkit-autofill:hover,
              input:-webkit-autofill:focus,
              input:-webkit-autofill:active {
                -webkit-box-shadow: 0 0 0px 1000px #fff inset;
                -webkit-text-fill-color: rgb(100, 116, 139);
              }
            `,
          },
        ]}
      />

      <div className="min-w-0 flex-1">
        {/* Hero Section */}
        <Section ariaLabelledby="intro-heading" id="intro">
          <h1 id="intro-heading">Modals</h1>
          <p>
            Dialogs inform users about a task and can contain critical
            information, require decisions, or involve multiple tasks.
          </p>

          {/* Hero Preview Section */}
          <section
            className="not-prose max-w-full"
            aria-multiselectable="false"
          >
            <div className="flex w-full items-center justify-center rounded-xl bg-wuiSlate-50 p-8 shadow-xl shadow-wuiSlate-200 ring-1 ring-wuiSlate-200 sm:p-16 [&>*]:w-full">
              <ModalHeroComponent />
            </div>
          </section>
        </Section>

        {/* Variations Section*/}
        <Section ariaLabelledby="variations-heading" id="variations-section">
          <h2 id="variations-heading">Variations</h2>

          <h3 id="ModalBasic">
            Basic Modal <span className="info-tip">Requires JS</span>
          </h3>

          <PreviewBlock
            id="ModalBasic"
            HtmlComponent={ModalBasicHTML.default}
            JsxComponent={ModalBasicJsx.default}
            getActiveTabs={getActiveTabs}
          >
            <div className="flex w-full items-center justify-center rounded-xl bg-white p-8 sm:p-16 ">
              <ModalBasicPreview
                ModalBasic={
                  activeTabs[0].active_tab === 1
                    ? ModalBasicHTML.default
                    : ModalBasicJsx.default
                }
              />
            </div>
          </PreviewBlock>

          <h3 id="ModalActionButton">
            Modal with single action button{" "}
            <span className="info-tip">Requires JS</span>
          </h3>

          <PreviewBlock
            id="ModalActionButton"
            HtmlComponent={ModalActionButtonHTML.default}
            JsxComponent={ModalActionButtonJsx.default}
            getActiveTabs={getActiveTabs}
          >
            <div className="flex w-full items-center justify-center rounded-xl bg-white p-8 sm:p-16 ">
              <ModalActionButtonPreview
                ModalActionButton={
                  activeTabs[1].active_tab === 1
                    ? ModalActionButtonHTML.default
                    : ModalActionButtonJsx.default
                }
              />
            </div>
          </PreviewBlock>

          <h3 id="ModalActionButtons">
            Modal with multiple action buttons{" "}
            <span className="info-tip">Requires JS</span>
          </h3>

          <PreviewBlock
            id="ModalActionButtons"
            HtmlComponent={ModalActionButtonsHTML.default}
            JsxComponent={ModalActionButtonsJsx.default}
            getActiveTabs={getActiveTabs}
          >
            <div className="flex w-full items-center justify-center rounded-xl bg-white p-8 sm:p-16 ">
              <ModalActionButtonsPreview
                ModalActionButtons={
                  activeTabs[2].active_tab === 1
                    ? ModalActionButtonsHTML.default
                    : ModalActionButtonsJsx.default
                }
              />
            </div>
          </PreviewBlock>

          <h3 id="ModalIconActionButtons">
            Modal with icon & action buttons{" "}
            <span className="info-tip">Requires JS</span>
          </h3>

          <PreviewBlock
            id="ModalIconActionButtons"
            HtmlComponent={ModalIconActionButtonsHTML.default}
            JsxComponent={ModalIconActionButtonsJsx.default}
            getActiveTabs={getActiveTabs}
          >
            <div className="flex w-full items-center justify-center rounded-xl bg-white p-8 sm:p-16 ">
              <ModalIconActionButtonsPreview
                ModalIconActionButtons={
                  activeTabs[3].active_tab === 1
                    ? ModalIconActionButtonsHTML.default
                    : ModalIconActionButtonsJsx.default
                }
              />
            </div>
          </PreviewBlock>

          <h3 id="ModalForm">
            Modal with form <span className="info-tip">Requires JS</span>
          </h3>

          <PreviewBlock
            id="ModalForm"
            HtmlComponent={ModalFormHTML.default}
            JsxComponent={ModalFormJsx.default}
            getActiveTabs={getActiveTabs}
          >
            <div className="flex w-full items-center justify-center rounded-xl bg-white p-8 sm:p-16 ">
              <ModalFormPreview
                ModalForm={
                  activeTabs[4].active_tab === 1
                    ? ModalFormHTML.default
                    : ModalFormJsx.default
                }
              />
            </div>
          </PreviewBlock>
        </Section>

        {/* Usage Section*/}
        <Section ariaLabelledby="usage-heading" id="usage-section">
          <h2 id="usage-heading">Usage</h2>
          <p>
            A dialog is a type of modal window that appears in front of app
            content to provide critical information or ask for a decision.
            Dialogs disable all app functionality when they appear, and remain
            on screen until confirmed, dismissed, or a required action has been
            taken.
          </p>
          <p>
            Dialogs are purposefully interruptive, so they should be used
            sparingly.
          </p>
          <ul>
            <li>
              Modals are built with HTML, CSS, and JavaScript. They’re
              positioned over everything else in the document and remove scroll
              from the <code>&lt;body&gt;</code> so that modal content scrolls
              instead.
            </li>
            <li>
              Clicking on the modal “backdrop” will automatically close the
              modal.
            </li>
            <li>
              Modals use <code>position: fixed</code>, which can sometimes be a
              bit particular about its rendering. Whenever possible, place your
              modal HTML in a top-level position to avoid potential interference
              from other elements.
            </li>
          </ul>
        </Section>

        {/* Specs Section */}
        <Section ariaLabelledby="specs-heading" id="specs">
          <h2 id="specs-heading">Specs</h2>
          <p>Modals consist of the following elements:</p>
          <ul>
            <li>
              <strong>Modal wrapper:</strong> that acts as a backdrop to make
              the user focus the attention on the modal.
            </li>
            <li>
              <strong>Modal:</strong> with 1.5rem (24px) internal padding.
            </li>
            <li>
              <strong>Modal header: </strong> The header of the modal (any size,
              usually 1.125rem (18px) or 1.25rem (20px)) with a close button
              (icon only, base size, see{" "}
              <Link to="/components/buttons">buttons</Link> ).
            </li>
            <li>
              <strong>Modal content: </strong> any HTML.
            </li>
            <li>
              <strong>Modal actions: </strong> a set of action buttons for the
              modal (<Link to="/components/buttons">buttons</Link> ).
            </li>
          </ul>
        </Section>

        {/* Accessibility Notes Section */}
        <Section
          ariaLabelledby="accessibility-notes-heading"
          id="accessibility-notes"
        >
          <h2 id="accessibility-notes-heading">Accessibility notes</h2>
          <p>
            For{" "}
            <a
              target="_blank"
              href="https://css-tricks.com/dialog-components-roll-your-own/"
            >
              better accessibility,
            </a>{" "}
            the native <code>&lt;dialog&gt;</code> is not used.
          </p>

          <p>To improve accessibility:</p>
          <ul>
            <li>
              The modal wrapper has a <code>role=&quot;dialog&quot;</code>
            </li>
            <li>
              The modal wrapper has an
              <code>aria-labelledby=&quot;...&quot;</code>, referencing the id
              of the modal heading and modal content.
            </li>
            <li>
              The modal has a <code>role=&quot;document&quot;</code>.
            </li>
            <li>
              Modals can be closed by clicking outside the modal or by pressing
              the <code>ESC</code> button.
            </li>
            <li>
              When the modal is open <code>TAB</code> and <code>SHIFT+TAB</code>{" "}
              should navigate only on elements inside the modal.
            </li>
            <li>
              When the modal is open it prevents the underlying body from
              scrolling.
            </li>
          </ul>
        </Section>
      </div>

      <ContentNavigation variations={activeTabs} />
    </SidebarLayout>
  )
}
